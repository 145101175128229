import $ from "jquery";
$(document).ready(function () {
    var music_btn_desktop = $("button.btn-music.desktop");
    function toggleMusicButton() {
        if (window.location.pathname === "/") {
            $(music_btn_desktop).addClass("d-lg-block");
        } else {
            $(music_btn_desktop).removeClass("d-lg-block");
        }
    }
    // Initial check when the page loads
    toggleMusicButton();
    // Disable ESLint for history.pushState and history.replaceState
    /* eslint-disable no-restricted-globals */
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;
    history.pushState = function (...args) {
        originalPushState.apply(this, args);
        toggleMusicButton(); // Call to update button visibility
    };
    history.replaceState = function (...args) {
        originalReplaceState.apply(this, args);
        toggleMusicButton(); // Call to update button visibility
    };
    // Listen to clicks on the music button
    $(music_btn_desktop).on("click", function () {
        var bg_video_desktop = $("video.bg-video.desktop");
        var isMuted = $(bg_video_desktop).prop('muted');
        $(bg_video_desktop).prop('muted', !isMuted);

        if (isMuted) {
            $(".mute-icon", music_btn_desktop).addClass("d-none");
            $(".un-mute-icon", music_btn_desktop).removeClass("d-none");
        } else {
            $(".mute-icon", music_btn_desktop).removeClass("d-none");
            $(".un-mute-icon", music_btn_desktop).addClass("d-none");
        }
    });


    var music_btn_mobile = $("button.btn-music.mobile");
    function toggleMusicButtonMobile() {
        if (window.location.pathname === "/") {
            $(music_btn_mobile).removeClass("d-none");
        } else {
            $(music_btn_mobile).addClass("d-none");
        }
    }
    // Initial check when the page loads
    toggleMusicButtonMobile();
    // Listen to clicks on the music button
    $(music_btn_mobile).on("click", function () {
        var bg_video_mobile = $("video.bg-video.desktop");
        var isMuted = $(bg_video_mobile).prop('muted');
        $(bg_video_mobile).prop('muted', !isMuted);

        if (isMuted) {
            $(".mute-icon", music_btn_mobile).addClass("d-none");
            $(".un-mute-icon", music_btn_mobile).removeClass("d-none");
        } else {
            $(".mute-icon", music_btn_mobile).removeClass("d-none");
            $(".un-mute-icon", music_btn_mobile).addClass("d-none");
        }
    });


    const originalPushState1 = history.pushState;
    const originalReplaceState1 = history.replaceState;
    history.pushState = function (...args) {
        originalPushState1.apply(this, args);
        toggleMusicButtonMobile(); // Call to update button visibility
    };
    history.replaceState = function (...args) {
        originalReplaceState1.apply(this, args);
        toggleMusicButtonMobile(); // Call to update button visibility
    };
});