import React, { Component } from "react";
import AOS from 'aos';
import Loader from './../pages/Loader';
import $ from "jquery";
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";
import {Link} from "react-router-dom";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    moveToTop() {
        window.scrollTo(0, 0);
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                <Loader />
                 <main>
                    <section className="banner banner-about-us">
                        <div className="container" data-aos="fade-up">
                            <h1 className="main-title text-center">Opt-In Consent</h1>
                        </div>
                    </section>
                    <section className="short-text bg-golden py-5">
                        <div className="container">
                            We're thrilled to assist you with your moving needs! To provide top-notch service, we need to collect and store your information. We may also record phone calls for training & quality purposes. Rest assured that any information we collect is used exclusively for coordinating your requested services and upholding our high standards.
                            <br/>
                            <br/>
                            By checking the box below, you consent to Big Guns Hospitality contacting you via email, phone, or text, including through automated systems. You have the right to request access to or deletion of your information at any time. If you wish to opt-out of certain communications, simply reply with "stop" or "unsubscribe," or ask your move consultant to adjust your preferences. Our goal is to make your move as seamless as possible. If you have any questions or would like to speak with us, please call us on 6221-2002-2013.
                            <h2 className="f-24 text-black mb-3 mt-4">Modifications to Terms</h2>
                            Big Guns Hospitality retains the right to modify the Terms governing the use of <span id="domainName"></span> at its sole discretion. The latest version of the Terms will replace all previous versions. We recommend that you review the Terms periodically to stay updated on any changes.
                            <h2 className="f-24 text-black mb-3 mt-4">Contact Information</h2>
                            Big Guns Hospitality welcomes your questions or comments regarding the Terms:
                            <br/>
                            <br/>
                            Big Guns Hospitality LLC
                            <br/>
                            1801 NE 123rd Street, Suite 314
                            <br/>
                            North Miami FL 33181
                            <br/>
                            <br/>
                            Email Address: info@bghosp.com
                            <br/>
                            <br/>
                            Phone Number: (786) 244-4867
                            <br/>
                            <br/>
                            Effective as of July 26, 2024
                        </div>
                    </section>
                    <section className="contact bg-gray" id="contact">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-lg-2">
                                    <div className="row justify-content-center text-center text-lg-start">
                                        <div className="col-lg-10">
                                            <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                            <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                            <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                North Miami FL 33181</p>
                                            <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                            <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="form-wrapper">
                                        <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                        <form data-aos="fade-up">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="First Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="Last Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_envelope} alt=""/>
                                                        <input type="email" className="form-control" placeholder="Email *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_phone} alt=""/>
                                                        <input type="phone" className="form-control" placeholder="Phone *" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mb-md-4">
                                                <img src={icon_message} alt=""/>
                                                <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                            </div>
                                            <div className="form-check mb-4 mb-md-5">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <button type="submit" className="btn btn-primary">Get In Touch</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}