import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from './../pages/Loader';
import $ from "jquery";
import AOS from "aos";

import new_img_6 from "../assets/img/webp/img-6.webp";
import new_img_7 from "../assets/img/webp/img-7.webp";
import new_img_8 from "../assets/img/webp/img-8.webp";
import PDFFile from "../assets/pdf/BGH Asset Management.pdf";
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    moveToTop() {
        window.scrollTo(0, 0);
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === /webp/
                        ? <Loader />
                        : <main>
                            <section className="banner banner-services">
                                <div className="container" data-aos="fade-up">
                                    <h1 className="main-title text-center">Our Services</h1>
                                </div>
                            </section>
                            <section className="short-text bg-golden">
                                <div className="container">
                                    <div className="text-center" data-aos="fade-up">
                                        <h2 className="text-black mb-4">A Comprehensive Suite Of Restaurant And Hotel <br /> Management Services
                                            To Benefit Any Business</h2>
                                        <p className="text-black mb-4">No two businesses are the same, especially in the food and beverage
                                            sector. That’s why we offer a
                                            <br /> comprehensive range of services that provide real value to your company, regardless
                                            of size or situation.</p>
                                    </div>
                                </div>
                            </section>
                            <section className="services bg-golden pt-5" id="services">
                                <div className="container-fluid px-1">
                                    <div className="row m-0 justify-content-center">
                                        <div className="col-sm-8 col-md-4 px-1 mb-2 mb-md-0">
                                            <Link to="/developing-new-concepts" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                                <img src={new_img_6} alt="" className="card-img" />
                                                <div className="card-body text-center">
                                                    <h2 className="f-36 mb-3">Developing New Concepts</h2>
                                                    <p className="card-text text-white">Learn More</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-sm-8 col-md-4 px-1 mb-2 mb-md-0">
                                            <Link to="/perfecting-existing-concepts" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                                <img src={new_img_7} alt="" className="card-img" />
                                                <div className="card-body text-center">
                                                    <h2 className="f-36 mb-3">Perfecting Existing Concepts</h2>
                                                    <p className="card-text text-white">Learn More</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-sm-8 col-md-4 px-1">
                                            <Link to="/optimizing-existing-operations" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                                <img src={new_img_8} alt="" className="card-img" />
                                                <div className="card-body text-center">
                                                    <h2 className="f-36 mb-3">Optimizing Existing Operations</h2>
                                                    <p className="card-text text-white">Learn More</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="text-center mt-5">
                                        <Link to="#" className="btn btn-outline-primary-dark" onClick={() => window.open(PDFFile)}>Download our Latest Brochure</Link>
                                    </div>
                                </div>
                            </section>
                            <section className="contact bg-gray" id="contact">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 order-lg-2">
                                            <div className="row justify-content-center text-center text-lg-start">
                                                <div className="col-lg-10">
                                                    <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                                    <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                                    <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                        North Miami FL 33181</p>
                                                    <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                                    <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-lg-1">
                                            <div className="form-wrapper">
                                                <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                                <form data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="First Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="Last Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_envelope} alt=""/>
                                                                <input type="email" className="form-control" placeholder="Email *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_phone} alt=""/>
                                                                <input type="phone" className="form-control" placeholder="Phone *" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_message} alt=""/>
                                                        <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                                    </div>
                                                    <div className="form-check mb-4 mb-md-5">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit" className="btn btn-primary">Get In Touch</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}