import React, { Component } from "react";
import AOS from 'aos';
import Loader from './../pages/Loader';
import $ from "jquery";
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";
import {Link} from "react-router-dom";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
                $(document).ready(function() {
                    $('#domainName').text(window.location.hostname);
                });
            },
            4000,
        );
    }
    moveToTop() {
        window.scrollTo(0, 0);
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                <Loader />
                <main>
                    <section className="banner banner-about-us">
                        <div className="container" data-aos="fade-up">
                            <h1 className="main-title text-center">Privacy Policy</h1>
                        </div>
                    </section>
                    <section className="short-text bg-golden py-5">
                        <div className="container">
                            <h2 className="f-24 text-black mb-3">Big Guns Hospitality Privacy Policy</h2>
                            This Privacy Policy ("Policy") applies to <span id="domainName"></span> and Big Guns Hospitality LLC ("Company") and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company include <span id="domainName"></span>. The Company's website is a news and information site. By using the Company website, you consent to the data practices described in this statement.
                            <h2 className="f-24 text-black mb-3 mt-4">Collection of Your Personal Information</h2>
                            To better provide you with our products and services, the Company may collect personally identifiable information, including:
                            <br/>
                            First and last name
                            <br/>
                            Mailing address
                            <br/>
                            Email address
                            <br/>
                            Phone number
                            <br/>
                            <br/>
                            We only collect personal information from you if you choose to provide it. You may need to share certain details when using specific products or services, such as: (a) creating an account; (b) entering a sweepstakes or contest we or our partners sponsor; (c) subscribing to special offers from selected third parties; (d) sending us an email; or (e) providing payment information for orders. We use your information primarily to communicate about the services and products you request. We might also collect additional personal or non-personal information in the future.
                            <h2 className="f-24 text-black mb-3 mt-4">How We Use Your Personal Information</h2>
                            The Company utilizes your personal information to: Provide and manage the services you’ve requested Deliver information, products, or services you ask for Send you updates regarding your account Fulfill our contractual obligations and uphold our rights Inform you about updates or changes to our website, products, or services Pursue any other purposes with your consent
                            <br/>
                            <br/>
                            The Company may also use your personal information to notify you about additional products or services offered by the Company and its affiliates.
                            <h2 className="f-24 text-black mb-3 mt-4">Sharing Information with Third Parties</h2>
                            The Company does not sell, rent, or lease customer information to third parties.
                            <h2 className="f-24 text-black mb-3 mt-4">Use of Cookies</h2>
                            Our website may use "cookies" to enhance your online experience. A cookie is a text file placed on your device by a web server. Cookies cannot run programs or transmit viruses to your computer. They are assigned uniquely to you and can only be read by the server that issued them.
                            <br/>
                            <br/>
                            Cookies primarily serve to make your experience more convenient. For example, if you customize our website or register for our services, cookies help us remember your preferences on future visits. This makes it easier to save your personal details, like billing and shipping addresses, so you can quickly access the features you've set up.
                            <br/>
                            <br/>
                            You have the option to accept or decline cookies. While most browsers accept cookies by default, you can adjust your browser settings to reject them if you prefer. Please note that declining cookies might affect your ability to fully utilize some interactive features of our services and websites.
                            <h2 className="f-24 text-black mb-3 mt-4">Security of Your Personal Information</h2>
                            We take steps to protect your personal information from unauthorized access, use, or disclosure. We use the following methods to ensure security:
                            <br/>
                            <br/>
                            SSL Protocol: Personal information, such as credit card numbers, is encrypted during transmission using Secure Sockets Layer (SSL) technology to protect it from interception.
                            <br/>
                            While we implement robust security measures to safeguard your data, no method of transmission over the Internet or wireless network is completely secure. Therefore, although we work diligently to protect your personal information, you understand and accept that: (a) there are inherent limitations to Internet security and privacy that are beyond our control, and (b) we cannot guarantee the absolute security, integrity, or privacy of any information exchanged between you and us through this site.
                            <h2 className="f-24 text-black mb-3 mt-4">Right to Deletion</h2>
                            Except where noted below, upon receiving a verifiable request from you, we will:
                            <br/>
                            <br/>
                            <ul>
                                <li>Remove your personal information from our records</li>
                                <li>Instruct our service providers to delete your personal information from their records</li>
                            </ul>
                            <br/>
                            Please be aware that we may not be able to fulfill requests for deletion of your personal information if it is necessary to:
                            <br/>
                            <br/>
                            <ul>
                                <li>Complete transactions for which the information was collBullet listected, or fulfill the terms of a warranty or product recall as required by law</li>
                                <li>Address security incidents, prevent or address fraudulent or illegal activities, or act against those responsible</li>
                                <li>Debug and fix errors affecting our services</li>
                                <li>Uphold free speech rights or ensure the rights of others</li>
                            </ul>
                            <h2 className="f-24 text-black mb-3 mt-4">Children’s Privacy</h2>
                            We do not intentionally collect personal information from individuals under the age of 13. If you are under 13, please obtain permission from a parent or guardian before using our website.
                            <h2 className="f-24 text-black mb-3 mt-4">Email Communications</h2>
                            Occasionally, we may reach out to you via email to share announcements, special offers, alerts, confirmations, surveys, or other updates.
                            <br/>
                            <br/>
                            If you wish to stop receiving marketing or promotional emails from us, you can opt out by replying with "STOP."
                            <h2 className="f-24 text-black mb-3 mt-4">Updates to This Policy</h2>

                            We may update this Policy periodically to reflect changes in our services, data protection practices, or legal requirements. Significant changes will be communicated to you through one or more of the following methods: an email to the address associated with your account, a prominent notice on our website, or updated privacy information. Continued use of our website and services following these updates means you accept and agree to the revised Policy.
                            <h2 className="f-24 text-black mb-3 mt-4">Contact Us</h2>
                            If you have any questions or concerns about this Policy or believe we have not adhered to it, please contact us at:
                            <br/>
                            <br/>
                            Big Guns Hospitality LLC
                            <br/>
                            1801 NE 123rd Street, Suite 314
                            <br/>
                            North Miami FL 33181
                            <br/>
                            <br/>
                            Email Address: info@bghosp.com
                            <br/>
                            <br/>
                            Phone Number: (786) 244-4867
                            <br/>
                            <br/>
                            Effective as of July 26, 2024
                        </div>
                    </section>
                    <section className="contact bg-gray" id="contact">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-lg-2">
                                    <div className="row justify-content-center text-center text-lg-start">
                                        <div className="col-lg-10">
                                            <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                            <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                            <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                North Miami FL 33181</p>
                                            <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                            <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="form-wrapper">
                                        <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                        <form data-aos="fade-up">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="First Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="Last Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_envelope} alt=""/>
                                                        <input type="email" className="form-control" placeholder="Email *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_phone} alt=""/>
                                                        <input type="phone" className="form-control" placeholder="Phone *" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mb-md-4">
                                                <img src={icon_message} alt=""/>
                                                <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                            </div>
                                            <div className="form-check mb-4 mb-md-5">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <button type="submit" className="btn btn-primary">Get In Touch</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}