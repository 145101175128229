import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './assets/scss/components/header.css';
import './assets/scss/components/footer.css';
import './assets/scss/components/section.css';
import './assets/scss/pages/style.css';
import './assets/js/components/custom.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "./pages/Layout";
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import OptimizingExistingOperations from './pages/OptimizingExistingOperations';
import PerfectingExistingConcepts from './pages/PerfectingExistingConcepts';
import DevelopingNewConcepts from './pages/DevelopingNewConcepts';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import OptInConsent from './pages/OptIn';


function App() {
    // const [isLoading, setIsLoading] = useState(true);
    //
    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 2500);
    // }, []);

  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="services" element={<Services />} />
                  <Route path="optimizing-existing-operations" element={<OptimizingExistingOperations />} />
                  <Route path="perfecting-existing-concepts" element={<PerfectingExistingConcepts />} />
                  <Route path="developing-new-concepts" element={<DevelopingNewConcepts />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-and-conditions" element={<TermsConditions />} />
                  <Route path="opt-in-consent" element={<OptInConsent />} />
                  <Route path="*" element={<Navigate to="/" />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;


