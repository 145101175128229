import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from './../pages/Loader';
import $ from "jquery";
import AOS from "aos";

import horizontalCardImg7 from './../assets/img/webp/horizontal-card-img-7.webp';
import horizontalCardImg8 from './../assets/img/webp/horizontal-card-img-8.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg9 from './../assets/img/webp/horizontal-card-img-9.webp';
import card1 from './../assets/img/webp/card-1-1.webp';
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === /webp/
                        ? <Loader />
                        : <main>
                            <section className="banner banner-3">
                                <div className="container" data-aos="fade-up">
                                    <h4 className="sub-title">Our Services</h4>
                                    <h1 className="main-title">Optimizing Existing Operations</h1>
                                </div>
                            </section>
                            <section className="horizontal-card bg-golden">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg7} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="text-black title">From Optimizing F<span class="span">&</span>B Management To Turning Around Faltering
                                                    Restaurants</h2>
                                                <p className="text-black opacity-75">Utilizing a fresh pair of eyes to look over your
                                                    operations is always a good idea. Processes and systems that you’ve become
                                                    accustomed to can be difficult to evaluate critically when they’ve served you
                                                    well for so long</p>
                                                <p className="text-black opacity-75">Newer, quicker, and more reliable processes become
                                                    available all the time however, and by failing to spot potential for positive
                                                    change, your business will find it much harder to experience meaningful
                                                    growth.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-card bg-black">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="title">Optimizing Existing Operations</h2>
                                                <h3 className="mb-4">Optimizing Operations In Long-Standing Businesses</h3>
                                                <p className="text-white opacity-75">It’s tempting to sit back when a business consistently
                                                    makes steady profits, and not want to change things up too much. Unfortunately,
                                                    this can also mean that you’re missing out on more potential income by not
                                                    making your business the best it can be.</p>
                                                <p className="text-white opacity-75">Our services and evaluations can help to identify areas in
                                                    your business that would benefit from change and execute them efficiently to
                                                    drive tangible growth.</p>
                                                <br />
                                                <h3 className="mb-4">Turning Around Businesses That Need Guidance</h3>
                                                <p className="text-white opacity-75">The food and beverage industry can be a tough sector
                                                    to compete in. Our F&B management services help businesses to make their
                                                    processes as efficient and profitable as possible.</p>
                                                <p className="text-white opacity-75">Big Guns Hospitality specializes in helping turn
                                                    around businesses. We measure our success by how successful our clients are,
                                                    so you can be assured that we’re just as invested in getting your business
                                                    back on track as you are.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 offset-lg-1">
                                            <img src={horizontalCardImg8} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2 img-30">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg9} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Our Optimizing Existing Operations Services</h1>
                                                <p className="mb-4">Big Guns Hospitality offers an array of services
                                                    designed to get the absolute most out of your business. Here’s just some
                                                    of the ways in which we can help you - </p>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Marketing</h3>
                                                        <ul>
                                                            <li>Re-branding Conceptualization and Operational
                                                                Restructuring
                                                            </li>
                                                            <li>Brand Consultation and Conversion</li>
                                                            <li>Marketing Plans</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Finances</h3>
                                                        <ul>
                                                            <li>Financial Performance Review</li>
                                                            <li>Liability Assessment</li>
                                                            <li>Selective Asset Management</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Operations</h3>
                                                        <ul>
                                                            <li>Operational Consulting: Design, Functionality, Culinary,
                                                                Beverage, Management, Systems
                                                            </li>
                                                            <li>Systems Implementation</li>
                                                            <li>Asset & Project Management</li>
                                                            <li>Third-party Restaurant Operator Liaison - JVs, Leases,
                                                                Management Deals
                                                            </li>
                                                            <li>Event Management</li>
                                                            <li>Menu Engineering</li>
                                                            <li>Cocktail, Beer, and Wine List Development</li>
                                                            <li>Hotel Food Consulting</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Day-To-Day Procedures</h3>
                                                        <ul>
                                                            <li>Due Diligence</li>
                                                            <li>Staffing and Hiring</li>
                                                            <li>Program Management</li>
                                                            <li>Sales Strategy</li>
                                                            <li>Human Resources</li>
                                                        </ul>
                                                        <h3 className="title">Buildings And Premises</h3>
                                                        <ul>
                                                            <li>Property Assessment</li>
                                                            <li>Expansions and Property Development</li>
                                                            <li>Kitchen and Bar Schematic Design; Equipment Layout and
                                                                Specification(s)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v3 bg-golden">
                                <div className="container">
                                    <div className="content">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="inner-content" data-aos="fade-up">
                                                    <p className="text-black">Big Guns Hospitality can make your business run to its full potential. Our
                                                        experience in the industry has seen us work with a multitude of different
                                                        companies of various sizes, so there’s very few challenges we haven’t
                                                        already tackled.</p>
                                                    <h1 className="main-title text-black mb-4">Get In Touch To Find Out How We Can Enhance Your
                                                        Current Operations.</h1>
                                                    <Link to="/services" className="btn btn-primary">BACK TO OUR SERVICES</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <img src={card1} alt="" className="img-fluid right-side-img w-100"
                                                     data-aos="fade-up" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="contact bg-gray" id="contact">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 order-lg-2">
                                            <div className="row justify-content-center text-center text-lg-start">
                                                <div className="col-lg-10">
                                                    <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                                    <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                                    <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                        North Miami FL 33181</p>
                                                    <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                                    <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-lg-1">
                                            <div className="form-wrapper">
                                                <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                                <form data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="First Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="Last Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_envelope} alt=""/>
                                                                <input type="email" className="form-control" placeholder="Email *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_phone} alt=""/>
                                                                <input type="phone" className="form-control" placeholder="Phone *" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_message} alt=""/>
                                                        <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                                    </div>
                                                    <div className="form-check mb-4 mb-md-5">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit" className="btn btn-primary">Get In Touch</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}