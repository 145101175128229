import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
// import './../assets/scss/component/header.scss';
// Images ==================
import Logo from './../assets/img/webp/logo.webp';
import menu_border from './../assets/img/webp/menu-border.webp';
import icon_clock from './../assets/img/webp/icon-clock.webp';
import icon_phone from './../assets/img/webp/icon-phone.webp';
import icon_pin from './../assets/img/webp/icon-pin.webp';
import icon_facebook from './../assets/img/webp/icon-facebook.webp';
import icon_instagram from './../assets/img/webp/icon-instagram.webp';
import muteIcon from "../assets/img/webp/icon-mic-off.webp";
import unMuteIcon from "../assets/img/webp/icon-mic.webp";

import $ from "jquery";

import 'aos/dist/aos.css';

export default class Header extends Component {

    componentDidMount() {
        var Header = $("header.header");
        function Header_Scroll(){
            var scroll = $(window).scrollTop();

            if (scroll >= $(Header).outerHeight()) {
                $(Header).addClass("scrolled");
            } else {
                $(Header).removeClass("scrolled");
            }
        }
        Header_Scroll();
        $(window).scroll(function() {
            Header_Scroll();
        });
    }

    load() {
        $('.navbar-toggler').click();
        window.scrollTo(0, 0);
    }

    menuCollapse() {
        window.scrollTo(0, 0);
    }

    scrollToSection(sectionId) {
        const headerHeight = $("header.header .header-bottom").outerHeight();
        const targetOffset = $(`#${sectionId}`).offset().top - headerHeight;
        $("html, body").animate({ scrollTop: targetOffset }, "slow");
    }

    render() {
        return (
            <>
                <header className="header">
                    <div className="header-top">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-8 col-lg-10">
                                    <ul className="list-s1">
                                        <li><img src={icon_clock} alt="" height="16" width="16"/>Mon - Fri 8:00 - 18:00 / Sunday 8:00 - 14:00</li>
                                        <li><img src={icon_phone} alt="" height="16" width="16"/><a href="tel:(786)244-4867" className="text-decoration-none">(786) 244-4867</a></li>
                                        <li><img src={icon_pin} alt="" height="16" width="16"/>1801 NE 123rd Street, Suite 314 North Miami FL 33181</li>
                                    </ul>
                                </div>
                                <div className="col-4 col-lg-2">
                                    <ul className="list-s2 justify-content-end">
                                        <li><a href="#"><img src={icon_facebook} alt="" height="16" width="16"/></a></li>
                                        <li><a href="#"><img src={icon_instagram} alt="" height="16" width="16"/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <Navbar expand="">
                            <div className="container">
                                <Link className="navbar-brand" to="/" onClick={this.menuCollapse}>
                                    <img src={Logo} alt="" />
                                </Link>
                                <Link to="#" className="btn btn-primary ms-auto me-4 d-none d-md-block" onClick={(e) => {e.preventDefault();this.scrollToSection("contact");}}>Get In Touch</Link>
                                <button className={'btn-music desktop d-none d-lg-block ms-auto ms-md-0 me-4'}>
                                    <img src={muteIcon} alt="" className={'icon mute-icon'}/>
                                    <img src={unMuteIcon} alt="" className={'icon un-mute-icon d-none'}/>
                                </button>
                                <button className={'btn-music mobile d-lg-none ms-auto ms-md-0 me-4'}>
                                    <img src={muteIcon} alt="" className={'icon mute-icon'}/>
                                    <img src={unMuteIcon} alt="" className={'icon un-mute-icon d-none'}/>
                                </button>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link className="nav-link active" aria-current="page" to="/" onClick={this.load}>Home</Link>
                                        </li>
                                        <NavDropdown title="Our Services" id="basic-nav-dropdown">
                                            <Link className={'dropdown-item'} to="/developing-new-concepts" onClick={this.load}>Developing New Concepts</Link>
                                            <Link className={'dropdown-item'} to="/perfecting-existing-concepts" onClick={this.load}>Perfecting Existing Concepts</Link>
                                            <Link className={'dropdown-item'} to="/optimizing-existing-operations" onClick={this.load}>Optimizing Existing Operations</Link>
                                        </NavDropdown>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/about" onClick={this.load}>About</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contact" onClick={this.load}>Contact Us</Link>
                                        </li>
                                    </ul>
                                    <img src={menu_border} alt="" className="menu-img" />
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                </header>
            </>
        );
    }

}
