import React, { Component } from "react";
import AOS from 'aos';
import Loader from './../pages/Loader';
import $ from "jquery";
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";
import {Link} from "react-router-dom";
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    moveToTop() {
        window.scrollTo(0, 0);
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                <Loader />
                 <main>
                    <section className="banner banner-about-us">
                        <div className="container" data-aos="fade-up">
                            <h1 className="main-title text-center">Terms & Conditions</h1>
                        </div>
                    </section>
                    <section className="short-text bg-golden py-5">
                        <div className="container">
                            <h2 className="f-24 text-black mb-3">Agreement Between User and <span id="domainName"></span></h2>
                            Welcome to <span id="domainName"></span>. This website (the "Site") is operated by Big Guns Hospitality LLC ("Big Guns Hospitality"). By using <span id="domainName"></span>, you agree to abide by and be bound by the terms, conditions, and notices outlined here (the "Terms"). Your use of the Site signifies your acceptance of these Terms. Please review them carefully and retain a copy for your records.
                            <br/>
                            <br/>
                            <span id="domainName"></span> is a news and information platform.
                            <br/>
                            <br/>
                            The primary aim of this website is to provide information about our hotel services and offer clients a free quote.
                            <h2 className="f-24 text-black mb-3 mt-4">Privacy</h2>
                            Your use of <span id="domainName"></span> is governed by Big Guns Hospitality's Privacy Policy. Please review our Privacy Policy, which details our data collection practices and how they apply to the Site.
                            <h2 className="f-24 text-black mb-3 mt-4">Electronic Communications</h2>
                            By visiting <span id="domainName"></span> or sending emails to Big Guns Hospitality, you consent to electronic communications. You agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email, or on the Site, satisfy any legal requirements for written communication.
                            <h2 className="f-24 text-black mb-3 mt-4">Children Under Thirteen</h2>
                            Big Guns Hospitality does not knowingly collect personal information from individuals under the age of thirteen, whether online or offline. If you are under 18, you may use <span id="domainName"></span> only with the permission of a parent or guardian.
                            <h2 className="f-24 text-black mb-3 mt-4">Links to Third-Party Sites and Services</h2>
                            <span id="domainName"></span> may contain links to external websites ("Linked Sites"). These Linked Sites are not controlled by Big Guns Hospitality, and we are not responsible for their content, including any links contained within those sites or any updates made to them. These links are provided solely for your convenience, and their inclusion does not imply endorsement by Big Guns Hospitality or any affiliation with their operators.
                            <br/>
                            <br/>
                            Certain services available through <span id="domainName"></span> are provided by third-party organizations. By using any service, product, or functionality from our Site, you agree that Big Guns Hospitality may share your information and data with these third parties as necessary to deliver the requested services on our behalf.
                            <h2 className="f-24 text-black mb-3 mt-4">Prohibited Uses and Intellectual Property</h2>
                            You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use <span id="domainName"></span> in accordance with these terms. You agree not to use the Site for any illegal or prohibited purposes. You must not damage, disable, overburden, or impair the Site or interfere with others’ use of it. Unauthorized attempts to access or obtain information through methods not provided by the Site are prohibited.
                            <br/>
                            <br/>
                            All content on the Site, including text, graphics, logos, images, and software, is owned by Big Guns Hospitality or its licensors and is protected by copyright and intellectual property laws. You agree to respect all copyright notices and proprietary rights associated with this content and not to alter or remove any such notices.
                            <br/>
                            <br/>
                            You may not modify, publish, transmit, reverse engineer, sell, create derivative works from, or exploit any content from the Site in any way. Content from the Site is not for resale. Your use of the Site does not grant you ownership rights to any protected content, and you may only use it for personal purposes unless explicitly authorized by Big Guns Hospitality and the copyright owner. No licenses, either express or implied, are granted to you for any intellectual property of Big Guns Hospitality or its licensors, except as expressly permitted by these Terms.
                            <h2 className="f-24 text-black mb-3 mt-4">International Users</h2>
                            The Service is managed and operated by Big Guns Hospitality from our offices in the USA. If you access the Service from outside the USA, you are responsible for complying with all local laws. You agree not to use any content from <span id="domainName"></span> in any country or in any manner that violates applicable laws, restrictions, or regulations.
                            <h2 className="f-24 text-black mb-3 mt-4">Indemnification</h2>
                            You agree to protect and hold Big Guns Hospitality, along with its officers, directors, employees, agents, and affiliates, harmless from any claims, damages, losses, or expenses, including legal fees, that arise from your use of or inability to use our Site or services. This includes any content you post, violations of these terms, infringement of others’ rights, or breaches of applicable laws or regulations. Big Guns Hospitality retains the right to assume full responsibility for any legal defense related to these indemnification obligations, and you agree to cooperate fully in any such defense efforts.
                            <h2 className="f-24 text-black mb-3 mt-4">DISCLAIMER OF LIABILITY</h2>
                            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PROVIDED ON THIS SITE MAY CONTAIN ERRORS OR INACCURACIES. UPDATES AND IMPROVEMENTS TO THE SITE AND ITS CONTENTS MAY OCCUR PERIODICALLY, AND Big Guns Hospitality AND ITS PARTNERS RESERVE THE RIGHT TO MAKE CHANGES AT ANY TIME.
                            <br/>
                            <br/>
                            Big Guns Hospitality AND ITS PARTNERS DO NOT GUARANTEE THE ACCURACY, RELIABILITY, AVAILABILITY, OR TIMELINESS OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS ON THIS SITE. ALL SUCH MATERIALS ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY OR CONDITION. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                            <br/>
                            <br/>
                            TO THE FULLEST EXTENT PERMITTED BY LAW, Big Guns Hospitality AND ITS PARTNERS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF USE, DATA, OR PROFITS, ARISING FROM OR RELATED TO THE USE OR PERFORMANCE OF THIS SITE. THIS INCLUDES ISSUES RELATED TO DELAYS, INABILITY TO USE THE SITE, PROVISION OR FAILURE TO PROVIDE SERVICES, OR ANY INFORMATION OBTAINED THROUGH THE SITE. THIS APPLIES REGARDLESS OF WHETHER THE CLAIM IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF Big Guns Hospitality HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            <br/>
                            <br/>
                            NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN DAMAGES, SO THESE LIMITATIONS MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PART OF THE SITE OR THESE TERMS, YOUR SOLE REMEDY IS TO STOP USING THE SITE.
                            <h2 className="f-24 text-black mb-3 mt-4">Termination and Access Restriction</h2>
                            Big Guns Hospitality reserves the right to terminate your access to the site and related services or any part thereof at its sole discretion, without prior notice. To the fullest extent allowed by law, this agreement is governed by the laws of the State of Colombia, and you agree to exclusive jurisdiction and venue in Colombia for any disputes arising from the use of the site. Use of the site in any jurisdiction that does not enforce all terms of these Terms, including this section, is prohibited.
                            <br/>
                            <br/>
                            You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and Big Guns Hospitality as a result of this agreement or your use of the site. Big Guns Hospitality's compliance with this agreement is subject to applicable laws and legal processes, and nothing in this agreement limits Big Guns Hospitality's right to comply with governmental, court, or law enforcement requests or requirements regarding your use of the site or information provided to or collected by Big Guns Hospitality.
                            <br/>
                            <br/>
                            If any provision of this agreement is found to be invalid or unenforceable under applicable law, including but not limited to warranty disclaimers and liability limitations, that provision will be replaced with a valid, enforceable provision that most closely matches the original intent. The remainder of the agreement will remain in full force and effect.
                            <br/>
                            <br/>
                            Unless otherwise stated, this agreement constitutes the complete agreement between you and Big Guns Hospitality regarding the site and replaces all prior communications and proposals, whether electronic, oral, or written, between you and Big Guns Hospitality. A printed copy of this agreement and any electronic notices shall be admissible in judicial or administrative proceedings to the same extent as other business documents maintained in printed form. Both parties expressly desire that this agreement and all related documents be written in English.
                            <h2 className="f-24 text-black mb-3 mt-4">Modifications to Terms</h2>
                            Big Guns Hospitality retains the right to modify the Terms under which the site is provided at its sole discretion. The latest version of the Terms will replace any previous versions. We recommend that you periodically review these Terms to stay updated on any changes.
                            <h2 className="f-24 text-black mb-3 mt-4">Contact Us</h2>
                            For any questions or comments about these Terms, please reach out to Big Guns Hospitality:
                            <br/>
                            <br/>
                            Big Guns Hospitality LLC
                            <br/>
                            1801 NE 123rd Street, Suite 314
                            <br/>
                            North Miami FL 33181
                            <br/>
                            <br/>
                            Email Address: info@bghosp.com
                            <br/>
                            <br/>
                            Phone Number: (786) 244-4867
                            <br/>
                            <br/>
                            Effective as of July 26, 2024
                        </div>
                    </section>
                    <section className="contact bg-gray" id="contact">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-lg-2">
                                    <div className="row justify-content-center text-center text-lg-start">
                                        <div className="col-lg-10">
                                            <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                            <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                            <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                North Miami FL 33181</p>
                                            <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                            <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="form-wrapper">
                                        <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                        <form data-aos="fade-up">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="First Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" className="form-control" placeholder="Last Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_envelope} alt=""/>
                                                        <input type="email" className="form-control" placeholder="Email *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_phone} alt=""/>
                                                        <input type="phone" className="form-control" placeholder="Phone *" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mb-md-4">
                                                <img src={icon_message} alt=""/>
                                                <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                            </div>
                                            <div className="form-check mb-4 mb-md-5">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <button type="submit" className="btn btn-primary">Get In Touch</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </>
        );
    }
}