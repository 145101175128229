import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from './../pages/Loader';
import $ from "jquery";
import AOS from "aos";

import horizontalCardImg41 from './../assets/img/webp/horizontal-card-img-4-1.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg5 from './../assets/img/webp/horizontal-card-img-5.webp';
import horizontalCardImg6 from './../assets/img/webp/horizontal-card-img-6.webp';
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: /webp/
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === /webp/
                        ? <Loader />
                        : <main>
                            <section className="banner banner-2">
                                <div className="container" data-aos="fade-up">
                                    <h4 className="sub-title">Our Services</h4>
                                    <h1 className="main-title">Perfecting Existing Concepts</h1>
                                </div>
                            </section>
                            <section className="horizontal-card bg-golden">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg41} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="text-black title">Can A Hospitality Management Company Really Enhance My
                                                    Business Concept?</h2>
                                                <p className="text-black opacity-75 mb-3">The short answer is ‘yes’, as long as you choose the
                                                    right one.</p>
                                                <h3 className="text-black mb-4">Perfecting Existing Concepts With A Hospitality Management Company</h3>
                                                <p className="text-black opacity-75">So you’ve got your new business concept
                                                    settled. Everything looks good, but how can you be sure that it’ll be successful
                                                    when it actually becomes a living, breathing, bricks and mortar concern of its
                                                    own?</p>
                                                <p className="text-black opacity-75">The best way to ensure success is to utilize
                                                    the skills and experience of a hospitality management company that’s done it
                                                    countless times before.</p>
                                                <p className="text-black opacity-75">Big Guns Hospitality has been working with
                                                    other businesses to optimize their operations for decades. We have the knowledge
                                                    and tools to ensure that your business runs to its full potential, and the
                                                    experience to avoid all the common pitfalls. By working with us, you give your
                                                    business concept the best possible start it can enjoy.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2 img-30">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg5} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Our Suite Of Services For <br /> Perfecting
                                                    Existing Concepts</h1>
                                                <p className="mb-4">Big Guns Hospitality offers an array of services
                                                    designed to get the absolute most out of your business. Here’s just some
                                                    of the ways in which we can help you - </p>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Marketing</h3>
                                                        <ul>
                                                            <li>Sales, marketing and public relations recommendations</li>
                                                            <li>Reviewing brand contribution and effectiveness</li>
                                                            <li>Market re-positioning</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Finances</h3>
                                                        <ul>
                                                            <li>Financial budget and P&L recommendations</li>
                                                            <li>CAPEX investment strategy</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Operations</h3>
                                                        <ul>
                                                            <li>Operational and development consulting</li>
                                                            <li>Leadership evaluations</li>
                                                            <li>Sales and digital recommendations</li>
                                                            <li>Strategic planning and organizational restructuring</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Working With Stakeholders/Third Parties</h3>
                                                        <ul>
                                                            <li>Negotiations with brands and developers</li>
                                                            <li>Franchise evaluation, selection and contract negotiations
                                                            </li>
                                                            <li>Regular and direct interface with brand and/or management
                                                                corporate office and local team
                                                            </li>
                                                            <li>Full management agreements</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v3 bg-golden">
                                <div className="container">
                                    <div className="content" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="inner-content">
                                                    <p className="text-black">Making use of our expertise now can prevent a whole host of headaches in the
                                                        future. Our hospitality management services have been behind the continued
                                                        success of countless food and beverage companies.</p>
                                                    <h1 className="main-title text-black mb-4">Get In Touch To Discuss How Big Guns Hospitality
                                                        Can Make Your Current Business Concept Bulletproof.</h1>
                                                    <Link to="/services" className="btn btn-primary">BACK TO OUR SERVICES</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <img src={horizontalCardImg6} alt=""
                                                     className="img-fluid right-side-img w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="contact bg-gray" id="contact">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 order-lg-2">
                                            <div className="row justify-content-center text-center text-lg-start">
                                                <div className="col-lg-10">
                                                    <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                                    <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                                    <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                        North Miami FL 33181</p>
                                                    <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                                    <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-lg-1">
                                            <div className="form-wrapper">
                                                <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                                <form data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="First Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="Last Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_envelope} alt=""/>
                                                                <input type="email" className="form-control" placeholder="Email *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_phone} alt=""/>
                                                                <input type="phone" className="form-control" placeholder="Phone *" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_message} alt=""/>
                                                        <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                                    </div>
                                                    <div className="form-check mb-4 mb-md-5">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit" className="btn btn-primary">Get In Touch</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}