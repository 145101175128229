import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Modal, Button} from 'react-bootstrap';

// Images ==================
import Logo from './../assets/img/webp/logo.webp';
import icon_envelope from './../assets/img/webp/icon-envelope.webp';
import icon_phone from './../assets/img/webp/icon-phone.webp';
import icon_pin from './../assets/img/webp/icon-pin.webp';
import checkIcon from './../assets/img/webp/check-icon.webp';



export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handleShow: false,
            handleClose: true
        };
    }

    handleClose() {
        this.setState({handleClose: false})
    }

    render() {
        const year = new Date().getFullYear();
        const {handleShow, handleClose} = this.state;
        return (
            <>
                <footer className="footer">
                    <div className="footer-top">
                        <div className="container">
                            <Link className="logo mb-5 mx-auto" to="/">
                                <img src={Logo} alt="Big Guns Hospitality" width="330" height="98" />
                            </Link>
                            <ul className="list-s3">
                                <li><img src={icon_pin} alt="" className="icon" /> 1801 NE 123rd Street, Suite 314
                                    North Miami FL 33181</li>
                                <li><img src={icon_envelope} alt="" className="icon" /> <a
                                    className="text-decoration-none"
                                    href="mailto:info@bghosp.com">info@bghosp.com</a></li>
                                <li><img src={icon_phone} alt="" className="icon" /> <a
                                    className="text-decoration-none phone text-end" href="tel:(786)244-4867">(786) 244-4867<br/><span>BIG-GUNS</span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="d-flex flex-column flex-lg-row">
                                <ul className="list-s4 mx-auto me-lg-0 mb-3 mb-lg-0 order-lg-2">
                                    <li className="nav-item"><Link to="/privacy-policy" className="nav-link text-decoration-none" onClick={this.moveToTop}>Privacy Policy</Link></li>
                                    <li className="nav-item"><Link to="/terms-and-conditions" className="nav-link text-decoration-none" onClick={this.moveToTop}>Terms & Conditions</Link></li>
                                    <li className="nav-item"><Link to="/opt-in-consent" className="nav-link text-decoration-none" onClick={this.moveToTop}>Opt-In Consent</Link></li>
                                </ul>
                                <p className="text-white m-0 text-center text-lg-start order-lg-1">© All Rights Reserved Big Guns Hospitality</p>
                            </div>
                        </div>
                    </div>
                </footer>
                <Modal centered className={'thankYouPopup'} show={handleShow} onHide={handleClose}>
                    <Modal.Body>
                        <div className="text-end">
                            <button onClick={this.handleClose} type="button" className="btn-close"></button>
                        </div>
                        <div className="text-center">
                            <img src={checkIcon} alt="" className="icon" />
                                <h2 className="title">Thank you!</h2>
                                <p>One of our representatives will contact you shortly.</p>
                                <button type="button" className="btn btn-dark btn-lg">Ok</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}