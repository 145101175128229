import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from './../pages/Loader';
import AOS from 'aos';
import EmailJs from '@emailjs/browser';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import $ from "jquery";

import videoDesktop from './../assets/img/desktop_video.mp4';
import desktopPoster from './../assets/img/webp/desktop-poster.webp';
import videoMobile from './../assets/img/mobile_video.mp4';
import mobilePoster from './../assets/img/webp/mobile-poster.webp';
import logo1 from './../assets/img/webp/logo1.webp';
import logo2 from './../assets/img/webp/logo2.webp';
import logo3 from './../assets/img/webp/logo3.webp';
import logo4 from './../assets/img/webp/logo4.webp';
import logo5 from './../assets/img/webp/logo5.webp';
import logo6 from './../assets/img/webp/logo6.webp';
import logo7 from './../assets/img/webp/bnp_paribas.webp';
import logo8 from './../assets/img/webp/logo8.webp';
import logo9 from './../assets/img/webp/logo9.webp';
import logo10 from './../assets/img/webp/logo10.webp';
import logo11 from './../assets/img/webp/w_hotel.webp';
import logo12 from './../assets/img/webp/logo12.webp';
import logo13 from './../assets/img/webp/logo13.webp';
import logo14 from './../assets/img/webp/logo14.webp';
import logo15 from './../assets/img/webp/logo15.webp';
import logo16 from './../assets/img/webp/logo16.webp';
import logo17 from './../assets/img/webp/logo17.webp';
import logo18 from './../assets/img/webp/logo18.webp';
import PDFFile from './../assets/pdf/BGH Asset Management.pdf';
import new_img_1 from './../assets/img/webp/img-1.webp';
import new_img_2 from './../assets/img/webp/img-2.webp';
import new_img_3 from './../assets/img/webp/img-3.webp';
import new_img_4 from './../assets/img/webp/img-4.webp';
import new_img_5 from './../assets/img/webp/img-5.webp';
import new_img_6 from './../assets/img/webp/img-6.webp';
import new_img_7 from './../assets/img/webp/img-7.webp';
import new_img_8 from './../assets/img/webp/img-8.webp';
import icon_arrow from './../assets/img/webp/icon-arrow.webp';
import icon_user from './../assets/img/webp/icon-user.webp';
import icon_envelope from './../assets/img/webp/icon-envelope-2.webp';
import icon_phone from './../assets/img/webp/icon-phone-2.webp';
import icon_message from './../assets/img/webp/icon-message.webp';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVideoLoaded: false,
            desktopMusic: /webp/,
            mobileMusic: /webp/,
        };
    }
    componentDidMount() {
        setTimeout(() => {
                $('.preloader').fadeOut();
            },
            3000,
        );
        setTimeout(() => {
                AOS.init();
            },
            3500,
        );
    }
    moveToTop() {
        window.scrollTo(0, 0);
    }
    scrollToSection(sectionId) {
        const headerHeight = $("header.header .header-bottom").outerHeight();
        const targetOffset = $(`#${sectionId}`).offset().top - headerHeight;
        $("html, body").animate({ scrollTop: targetOffset }, "slow");
    }
    render() {
        const settings = {
            dots: false,
            array: /webp/,
            infinite: /webp/,
            speed: 500,
            slidesToShow: 9,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1380,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1180,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 567,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        const onSubmit = (event) => {
            event.preventDefault();

            // EmailJs.sendForm('serrvice_id', 'templ', e.target, 'pub');
        };
        return (
            <>
                <Loader />
                <main>
                <section className="hero">
                    <video playsInline muted="/webp/" autoPlay loop poster={desktopPoster} className={'bg-video desktop d-none d-lg-block'}>
                        <source src={videoDesktop} type="video/mp4" />
                    </video>
                    <video playsInline muted="/webp/" autoPlay loop poster={mobilePoster} className={'bg-video mobile d-lg-none'}>
                        <source src={videoMobile} type="video/mp4" />
                    </video>
                    <Link to="/about" className="btn scroll-down" onClick={(e) => {e.preventDefault();this.scrollToSection("about");}}><img src={icon_arrow} alt=""/></Link>
                    <div className="container text-center text-lg-start">
                        <h1 className="f-90 text-white mb-3"><span className="text-golden f-64">A Restaurant and Hotel</span> <br/>Management Company <br/>That Drives Real Growth</h1>
                        <div className="row">
                            <div className="col-lg-7">
                                <p className="f-20 text-white mb-4">Big Guns Hospitality is dedicated to transforming F&B ventures with industry-leading expertise. From restaurant launches to hotel management, we provide the tools and support to drive real growth and lasting success.</p>
                            </div>
                        </div>
                        <Link to="#" className="btn btn-outline-primary-light" onClick={(e) => {e.preventDefault();this.scrollToSection("services");}}>Our Services</Link>
                    </div>
                </section>
                <section className="about bg-golden" id="about">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-10">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-sm-10 col-md-8 col-lg-5 pe-lg-4">
                                        <div className="img-wrapper mb-4 mb-sm-5 mb-lg-0">
                                            <img src={new_img_1} alt="" className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 ps-lg-4">
                                        <div className="text-center text-lg-start" data-aos="fade-up">
                                            <h2 className="f-24 text-golden text-uppercase">Big Guns Hospitality</h2>
                                            <h2 className="f-64 text-black">About Us</h2>
                                            <p className="f-20 mb-4">Big Guns Hospitality specializes in creating enterprise value for businesses serving food and beverages. Whether it’s a hotel management company you need, or help running a new restaurant, we’ve got you covered. With a wealth of experience in the sector, we have the tools your business needs to get ahead of the competition.</p>
                                            <Link to="/about" className="btn btn-primary" onClick={this.moveToTop}>Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hospitality bg-gray">
                    <div className="container">
                        <h2 className="f-24 text-golden text-uppercase text-center">Big Guns Hospitality</h2>
                        <h2 className="f-64 text-white text-center mb-5">Empowering Growth in Hospitality</h2>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="card h-100" data-aos="fade-up">
                                    <img src={new_img_2} alt="" className="card-img" />
                                    <div className="card-body text-white">
                                        <h2 className="f-36 card-title mb-2 mb-sm-3">We Get Results - FAST</h2>
                                        <p className="card-text f-20">Working with a wide array of food and beverage focused businesses across
                                            all sectors, Big Guns Hospitality have the means to deliver prompt and
                                            reliable results whether you’re a fresh start-up restaurant, or
                                            multinational hotel chain.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <div className="card h-100" data-aos="fade-up">
                                    <img src={new_img_3} alt="" className="card-img" />
                                    <div className="card-body text-white">
                                        <h2 className="f-36 card-title mb-2 mb-sm-3">Your Success Is Our Success</h2>
                                        <p className="card-text f-20">Our business relies on the success that we bring to our clients, and the references that they provide. This means that you can be assured that Big Guns Hospitality consistently strives to drive added value to your business and treat it with the same care and consideration as you would yourself.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card h-100" data-aos="fade-up">
                                    <img src={new_img_4} alt="" className="card-img" />
                                    <div className="card-body text-white">
                                        <h2 className="f-36 card-title mb-2 mb-sm-3">An Essential For New Restaurant Ventures</h2>
                                        <p className="card-text f-20">We all know the risks inherent with opening up new restaurants, but by utilizing the skills and knowledge of a specialist team, you can ensure the success of your new venture.<br/>
                                            Put simply, we know what works. By working with you to provide guidance on the layout and architecture of your premises, you can give your new restaurant the best possible start. We can even help with engineering your menus and wine lists to provide the best possible experience for customers, and profitability for you.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card h-100" data-aos="fade-up">
                                    <img src={new_img_5} alt="" className="card-img" />
                                    <div className="card-body text-white">
                                        <h2 className="f-36 card-title mb-2 mb-sm-3">Hotel Management Company</h2>
                                        <p className="card-text f-20">Overseeing the food and beverage side of hotel operations can be a monumental undertaking, especially when there’s already the sizable task of running a successful hotel to take care of. That’s why clients such as Marriott rely on us to take care of their food and drink operations in their flagship South Beach, Miami hotel. We can manage every aspect from staffing, to negotiating with suppliers. This ensures the servicing element of your hotel becomes a real feature for customers, and financial benefit for your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <Link to="#" className="btn btn-primary" onClick={(e) => {e.preventDefault();this.scrollToSection("services");}}>Our Services</Link>
                        </div>
                    </div>
                </section>
                <section className="affiliated">
                    <div className="container">
                        <h2 className="f-24 text-golden text-uppercase text-center">Big Guns Hospitality</h2>
                        <h2 className="f-64 text-black text-center mb-5">Affiliated With The Following Brands</h2>
                        <Slider {...settings} className="logos" data-aos="fade-up">
                            <div>
                                <img src={logo1} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo2} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo3} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo4} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo5} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo6} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo7} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo8} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo9} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo10} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo11} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo12} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo13} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo14} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo15} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo16} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo17} alt="" className="icon" />
                            </div>
                            <div>
                                <img src={logo18} alt="" className="icon" />
                            </div>
                        </Slider>
                    </div>
                </section>
                <section className="services bg-golden" id="services">
                    <div className="container">
                        <h2 className="f-24 text-golden text-uppercase text-center">Big Guns Hospitality</h2>
                        <h2 className="f-64 text-black text-center mb-5">Our Services</h2>
                    </div>
                    <div className="container-fluid px-1">
                        <div className="row m-0 justify-content-center">
                            <div className="col-sm-8 col-md-4 px-1 mb-2 mb-md-0">
                                <Link to="/developing-new-concepts" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                    <img src={new_img_6} alt="" className="card-img" />
                                    <div className="card-body text-center">
                                        <h2 className="f-36 mb-3">Developing New Concepts</h2>
                                        <p className="card-text text-white">Learn More</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-8 col-md-4 px-1 mb-2 mb-md-0">
                                <Link to="/perfecting-existing-concepts" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                    <img src={new_img_7} alt="" className="card-img" />
                                    <div className="card-body text-center">
                                        <h2 className="f-36 mb-3">Perfecting Existing Concepts</h2>
                                        <p className="card-text text-white">Learn More</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-8 col-md-4 px-1">
                                <Link to="/optimizing-existing-operations" onClick={this.moveToTop} className="card h-100" data-aos="fade-up">
                                    <img src={new_img_8} alt="" className="card-img" />
                                    <div className="card-body text-center">
                                        <h2 className="f-36 mb-3">Optimizing Existing Operations</h2>
                                        <p className="card-text text-white">Learn More</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mt-5">
                            <div className="col-lg-8 col-xxl-7">
                                <div className="row">
                                    <div className="col-md-6 col-xxl">
                                        <Link to="/services" className="btn btn-primary mb-3 mb-lg-0 w-100" onClick={this.moveToTop}>Learn More</Link>
                                    </div>
                                    <div className="col-md-6 col-xxl">
                                        <Link to="#" className="btn btn-outline-primary-dark w-100" onClick={() => window.open(PDFFile)}>Download our Latest Brochure</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact bg-gray" id="contact">
                    <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-lg-2">
                                <div className="row justify-content-center text-center text-lg-start">
                                    <div className="col-lg-10">
                                        <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                        <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                        <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                            North Miami FL 33181</p>
                                        <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                        <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                    </div>
                                </div>
                            </div>
                                <div className="col-lg-6 order-lg-1">
                                    <div className="form-wrapper">
                                        <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                        <form onSubmit={onSubmit} data-aos="fade-up">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" name="f-name" className="form-control" placeholder="First Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_user} alt=""/>
                                                        <input type="text" name="l-name" className="form-control" placeholder="Last Name *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_envelope} alt=""/>
                                                        <input type="email" name="email" className="form-control" placeholder="Email *" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_phone} alt=""/>
                                                        <input type="phone" name="phone" className="form-control" placeholder="Phone *" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mb-md-4">
                                                <img src={icon_message} alt=""/>
                                                <textarea name="message" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                            </div>
                                            <div className="form-check mb-4 mb-md-5">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <button type="submit" className="btn btn-primary">Get In Touch</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </main>
            </>
        );
    }
}