import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from './../pages/Loader';
import $ from "jquery";
import AOS from "aos";

import horizontalCardImg1 from './../assets/img/webp/horizontal-card-img-1.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg2 from './../assets/img/webp/horizontal-card-img-2.webp';
import horizontalCardImg3 from './../assets/img/webp/horizontal-card-img-3.webp';
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                        ? <Loader />
                        : <main>
                            <section className="banner banner-1">
                                <div className="container" data-aos="fade-up">
                                    <h4 className="sub-title">Our Services</h4>
                                    <h1 className="main-title">Developing New Concepts</h1>
                                </div>
                            </section>
                            <section className="horizontal-card bg-golden">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg1} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="text-black title">Trust A Hospitality Company With Experience Of Ensuring New
                                                    Ventures Are Successful</h2>
                                                <p className="text-black">When it comes to taking your new concept from paper to bricks and mortar, there’s
                                                    no better hospitality company to call upon.</p>
                                                <p className="text-black">Big Guns Hospitality has an enviable reputation of starting up and running
                                                    successful F&B-focused businesses. We know what it takes to ensure a profitable
                                                    and growth-ready venture. Because of this, we’ve been able to create a suite of
                                                    services that provide genuine value to any hospitality-based start up.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg2} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 col-xl-6">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Developing New Concepts</h1>
                                                <p className="mb-4">Our experience in the field of hospitality and F&B
                                                    ensures that your new venture will be equipped for any eventuality, and
                                                    well placed to run as smoothly as possible.
                                                    <br /> Big Guns Hospitality provides everything you need to bring your
                                                    exciting new concepts to life. We take care of everything required
                                                    to make any start up thrive.</p>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Premises</h3>
                                                        <ul>
                                                            <li>Architectural and interior design</li>
                                                            <li>Construction project management</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Marketing</h3>
                                                        <ul>
                                                            <li>Brand creation and development</li>
                                                            <li>Market positioning</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h3 className="title">Operations</h3>
                                                        <ul>
                                                            <li>Complete turnkey operational development</li>
                                                            <li>Financial systems implementation</li>
                                                            <li>Ongoing asset management</li>
                                                            <li>Product and programming development</li>
                                                            <li>Proforma development</li>
                                                            <li>Culinary and beverage program development</li>
                                                            <li>Banquet, catering and special event sales</li>
                                                            <li>Hiring and staffing</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>
                            <section className="horizontal-v3 bg-golden">
                                <div className="container">
                                    <div className="content" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-lg-6 order-2 order-lg-1">
                                                <div className="d-flex align-items-center h-100">
                                                    <div className="inner-content">
                                                        <h1 className="text-black main-title">Utilize A Successful Hospitality Company’s
                                                            Experience</h1>
                                                        <p className="text-black">Our set of development services is designed to take as much stress out of
                                                            launching a new business as possible, while still guaranteeing the best
                                                            possible start. Our practical knowledge of running successful food and
                                                            beverage ventures means that we know what works and doesn’t work for
                                                            customers. By utilizing our expertise, you can save time and expense by
                                                            avoiding costly mistakes along the way.</p>
                                                        <Link to="/services" className="btn btn-primary">BACK TO OUR SERVICES</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2">
                                                <img src={horizontalCardImg3} alt=""
                                                     className="img-fluid right-side-img w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="contact bg-gray" id="contact">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 order-lg-2">
                                            <div className="row justify-content-center text-center text-lg-start">
                                                <div className="col-lg-10">
                                                    <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                                    <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                                    <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                        North Miami FL 33181</p>
                                                    <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                                    <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-lg-1">
                                            <div className="form-wrapper">
                                                <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                                <form data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="First Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="Last Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_envelope} alt=""/>
                                                                <input type="email" className="form-control" placeholder="Email *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_phone} alt=""/>
                                                                <input type="phone" className="form-control" placeholder="Phone *" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_message} alt=""/>
                                                        <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                                    </div>
                                                    <div className="form-check mb-4 mb-md-5">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit" className="btn btn-primary">Get In Touch</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}