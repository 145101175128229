import React, { Component } from "react";
import {Link} from "react-router-dom";
import AOS from 'aos';
import Loader from './../pages/Loader';
import $ from "jquery";

import about1 from './../assets/img/webp/about-1.webp';
import about2 from './../assets/img/webp/about-2.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg10 from './../assets/img/webp/horizontal-card-img-10.webp';
import horizontalCardImg8 from './../assets/img/webp/horizontal-card-img-8.webp';
import icon_user from "../assets/img/webp/icon-user.webp";
import icon_envelope from "../assets/img/webp/icon-envelope-2.webp";
import icon_phone from "../assets/img/webp/icon-phone-2.webp";
import icon_message from "../assets/img/webp/icon-message.webp";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }
    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }
    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                    ? <Loader />
                        : <main>
                            <section className="banner banner-about-us">
                                <div className="container" data-aos="fade-up">
                                    <h1 className="main-title text-center">About Us</h1>
                                </div>
                            </section>
                            <section className="short-text bg-golden">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2">
                                            <div className="text-center" data-aos="fade-up">
                                                <h2 className="mb-4 text-black">An F<span className="span">&</span>B And Restaurant Management Company With The Experience To
                                                    Make A Difference</h2>
                                                <p className="mb-4 text-black opacity-75">Big Guns Hospitality prides itself on the
                                                    professionalism and reliability that our clients have come to expect from us.
                                                    With our wealth of experience in the sector, we’re fast becoming the go-to
                                                    choice for businesses wanting a safe pair of hands to manage their food and
                                                    beverage based interests. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="cards bg-golden pt-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="card border-0 rounded-0" data-aos="fade-up">
                                                <img src={about1} alt="" className="card-img h-auto" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card border-0 rounded-0" data-aos="fade-up">
                                                <img src={about2} alt="" className="card-img h-auto" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg10} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Our Founder’s Background In Start-Ups, Turnaround
                                                    Companies, And F<span className="span">&</span>B Management</h1>
                                                <p className="text-white opacity-75 mb-4">With over 30 years in venture capital,
                                                    investment banking and hospitality, John Guarnieri made his name helping
                                                    start ups, growth companies, and firms that needed to turn around their
                                                    fortunes.</p>
                                                <p className="text-white opacity-75 mb-4">His success in this area complimented his
                                                    previous work at Compass Group (a $33 billion global provider of F&B
                                                    management services). During his time there, he managed several
                                                    world-class sporting and entertainment events and restaurants with full
                                                    fiduciary and operations responsibility.</p>
                                                <p className="text-white opacity-75 mb-4">This experience lends him the unique ability
                                                    to see F&B businesses from an owner’s, investor’s and operator’s
                                                    perspective: a valuable ability in various scenarios. With this in mind,
                                                    he founded Big Guns Hospitality, where he and his team were able to
                                                    share this knowledge to F&B businesses nationwide.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-card bg-golden">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg8} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="text-black title">An F<span className="span">&</span>B And Restaurant Management Company That Makes A
                                                    Difference</h2>
                                                <p className="text-black opacity-75">Big Guns Hospitality has since grown to become the large
                                                    elite team of food and beverage experts that we know today. By sourcing the best
                                                    talent available in the sector, we’re able to ensure that businesses of all
                                                    sizes can realize their potential in the food and beverage sector.</p>
                                                <p className="text-black opacity-75">With clients ranging from luxury hotels to independently
                                                    owned restaurants, Big Guns Hospitality is unparalleled in the experience and
                                                    expertise that it can offer. We pride ourselves on creating real value for our
                                                    clients, with our services scalable to any amount of growth.</p>
                                                <p className="text-black opacity-75">In fact, we’ve been the trusted management team behind the
                                                    award-winning F&B outlets at Marriott’s luxury flagship W Hotel South Beach
                                                    premises for over the last decade.</p>
                                                <Link to="/services" className="btn btn-primary mt-4" onClick={this.moveToTop}>Our Services</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="contact bg-gray" id="contact">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 order-lg-2">
                                            <div className="row justify-content-center text-center text-lg-start">
                                                <div className="col-lg-10">
                                                    <h2 className="f-24 text-golden text-uppercase">Contact Us</h2>
                                                    <h2 className="f-48 text-white mb-4">Discover How We Add<br/> Value to Your Business</h2>
                                                    <p className="text-gray-light mb-3">1801 NE 123rd Street, Suite 314<br/>
                                                        North Miami FL 33181</p>
                                                    <a className="text-gray-light mb-4 d-block text-decoration-none" href="mailto:info@bghosp.com">info@bghosp.com</a>
                                                    <h5 className="text-white f-24 mb-4 mb-sm-5 mb-lg-0"><a className="text-white text-decoration-none" href="tel:(786)244-4867">(786) 244-4867</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 order-lg-1">
                                            <div className="form-wrapper">
                                                <h2 className="f-48 text-black text-center text-lg-start mb-4 mb-md-5">Get In Touch</h2>
                                                <form data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="First Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_user} alt=""/>
                                                                <input type="text" className="form-control" placeholder="Last Name *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_envelope} alt=""/>
                                                                <input type="email" className="form-control" placeholder="Email *" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3 mb-md-4">
                                                                <img src={icon_phone} alt=""/>
                                                                <input type="phone" className="form-control" placeholder="Phone *" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 mb-md-4">
                                                        <img src={icon_message} alt=""/>
                                                        <textarea name="" id="" cols="30" rows="2" className="form-control" placeholder="Message"></textarea>
                                                    </div>
                                                    <div className="form-check mb-4 mb-md-5">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">I have read and agree to the <Link className="text-decoration-none" to="/opt-in-consent" onClick={this.moveToTop}>Opt In Consent</Link>, <Link className="text-decoration-none" to="/privacy-policy" onClick={this.moveToTop}>Privacy Policy</Link>, <Link className="text-decoration-none" to="/terms-and-conditions" onClick={this.moveToTop}>Terms & Conditions</Link></label>
                                                    </div>
                                                    <div className="text-center text-lg-start">
                                                        <button type="submit" className="btn btn-primary">Get In Touch</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}